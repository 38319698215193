<template>
  <div class="accordion mb-3.5" :class="$props.type" ref="accordion">
    <div class="accordion-inner">
      <div class="accordion-head cursor-pointer flex justify-between pt-3.5 pb-4 items-start" :class="$props.head_type" @click="this.onClick()">
        {{ $props.head }} <Icons type="angle-down" classes="text-icon-big ml-9" />
      </div>
      <div class="accordion-body" ref="body">
        <div class="accordion-content pb-4 pt-1" ref="content" v-html="compiledContent">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import filterService from '@/services/filterService';

const Icons = defineAsyncComponent(() => import('@/components/Icons'));
export default {
  name: "Accordions",
  props: ["type", "head", "head_type", "content"],
  components: {
    Icons
  },
  data () {
    return {
      open: false,
      compiledContent: ''
    }
  },
  created() {
    this.compiledContent = filterService.textCompile(this.$props.content, {emojis: true, openLinksInNewWindow: false});
  },
  methods: {
    onClick() {
      if(this.open === true) {
        this.closeAccordion();
      } else {
        this.openAccordion();
      }
    },
    openAccordion() {
      const body = this.$refs.body;
      const content = this.$refs.content;
      const accordion = this.$refs.accordion;

      accordion.classList.add('active');
      body.style.height = '' + content.clientHeight + 'px';
      this.open = true;
    },
    closeAccordion() {
      this.$refs.accordion.classList.remove('active');
      this.$refs.body.style.height = '0';
      this.open = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.accordion {

  &.single {
    border: 4px solid var(--mint-50-);

    .accordion-head,
    .accordion-body {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }

    &.active {

      .accordion-inner {
        background: var(--mint-50-);
      }

      .accordion-head {

        color: var(--pumpkin);
      }
    }
  }

  &.active {

    .accordion-head {

      .fas {

        &::before {
          content: "\f106"
        }
      }
    }
  }

  .accordion-head {

    line-height: var(--line-spacing-22);

    .fas {
      line-height: var(--line-spacing-22);
      color: var(--green-dark);
      font-weight: normal;
    }

    &.big {
      font-weight: var(--font-weight-900);
      color: var(--pumpkin-light);
      font-size: var(--font-size-20);
    }

    &.small {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-16);
      color: var(--black);
      letter-spacing: var(--character-spacing--0-16);
    }

    &:hover {

      color: var(--pumpkin);

      .fas {

        color: var(--pumpkin);
      }
    }
  }

  .accordion-body {

    transition: height 0.5s;
    height: 0;
    overflow: hidden;
  }

  &.list {
    border-top: 4px solid var(--mint-50-);
    border-bottom: 4px solid transparent;
    margin-bottom: 0;

    &:last-child {

      border-color: var(--mint-50-);
    }
  }
}
</style>
